<template>
  <div>
    <Header />
    <Content />
    <Contact />
    <Footer />
  </div>
</template>

<script>
import Content from "./Content.vue";
import Footer from "@/components/layouts/Footer.vue";
import Header from "@/components/layouts/Header.vue";
import Contact from "@/components/layouts/Contact.vue";

export default {
  name: "TermAndCondition",
  components: {
    Header,
    Content,
    Contact,
    Footer,
  },
  mounted() {
    document.title = "Terms and Conditions - Carbon Addons";
    this.$gtag.pageview(window.location.href);
  },
};
</script>
