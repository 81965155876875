<template>
  <section id="contact-us" class="iq-full-contact white-bg">
    <div class="container-fluid">
      <div class="row no-gutter">
        <div class="col-sm-6">
          <div class="iq-map">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3957.8562329886963!2d112.74496551478234!3d-7.2571982733109115!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2dd7f9658f6f92eb%3A0x412d8891d03e4e5e!2sSatu%20Atap%20Co-Working%20Place%20And%20Food%20Station!5e0!3m2!1sid!2sid!4v1622346280011!5m2!1sid!2sid"
              style="border: 0"
              allowfullscreen
            ></iframe>
          </div>
        </div>
        <div class="col-sm-6 iq-mt-20 iq-pall-40">
          <h4 class="heading-left iq-tw-6 iq-pb-20">Get in Touch</h4>
          <div class="row">
            <div
              id="contact-us-alert"
              v-if="alertMessage"
              class="alert"
              role="alert"
              v-bind:class="alertType"
            >
              {{ this.alertMessage }}
              <button type="button" class="close" v-on:click="closeAlertEvent">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
          </div>
          <div class="row">
            <form class="form-horizontal">
              <div class="contact-form">
                <div class="col-sm-6">
                  <div class="section-field">
                    <input type="text" placeholder="Name*" v-model="name" />
                  </div>
                  <div class="section-field">
                    <input type="email" placeholder="Email*" v-model="email" />
                  </div>
                  <div class="section-field">
                    <input type="text" placeholder="Phone*" v-model="phone" />
                  </div>
                </div>
                <div class="col-sm-6">
                  <div class="section-field textarea">
                    <textarea
                      class="input-message"
                      placeholder="Comment*"
                      rows="7"
                      v-model="comment"
                    ></textarea>
                  </div>
                  <button
                    value="Send"
                    type="button"
                    class="button pull-right iq-mt-40"
                    v-on:click="sendEvent"
                  >
                    Send
                  </button>
                </div>
              </div>
            </form>
            <div id="ajaxloader" style="display: none">
              <img
                class="center-block mt-30 mb-30"
                src="assets/images/ajax-loader.gif"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="row iq-ptb-80">
        <div class="col-sm-6 col-md-4 col-lg-4">
          <div class="iq-fancy-box-04">
            <div class="iq-icon green-bg">
              <i aria-hidden="true" class="ion-ios-location-outline"></i>
            </div>
            <div class="fancy-content">
              <h5 class="iq-tw-6">Address</h5>
              <span class="lead iq-tw-6"
                >Pacar St. No. 2, Ketabang, Genteng, Surabaya, Indonesia,
                60272</span
              >
            </div>
          </div>
        </div>
        <div class="col-sm-6 col-md-4 col-lg-4">
          <div class="iq-fancy-box-04">
            <div class="iq-icon green-bg">
              <i aria-hidden="true" class="ion-ios-telephone-outline"></i>
            </div>
            <div class="fancy-content">
              <h5 class="iq-tw-6">Phone</h5>
              <span class="lead iq-tw-6">+6231 33100483</span>
              <p class="iq-mb-0">Mon-Fri 8:00am - 5:00pm</p>
            </div>
          </div>
        </div>
        <div class="col-sm-6 col-md-4 col-lg-4">
          <div class="iq-fancy-box-04">
            <div class="iq-icon green-bg">
              <i aria-hidden="true" class="ion-ios-email-outline"></i>
            </div>
            <div class="fancy-content">
              <h5 class="iq-tw-6">Mail</h5>
              <span class="lead iq-tw-6">info@carbonaddons.id</span>
              <p class="iq-mb-0">24 X 7 online support</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "Header",
  components: {},
  data() {
    return this.initialState();
  },
  methods: {
    initialState() {
      return {
        name: "",
        email: "",
        phone: "",
        comment: "",
        alertMessage: "",
        alertType: "",
        path: "/api/home-page/contact-us",
      };
    },
    resetState() {
      Object.assign(this.$data, this.initialState());
    },
    sendEvent() {
      this.alertMessage = "";
      this.alertType = "";

      if (this.name === "") {
        this.alertMessage = "name is required";
        this.alertType = "alert-danger";
      }

      if (this.email === "") {
        this.alertMessage = "email is required";
        this.alertType = "alert-danger";
      }

      if (this.phone === "") {
        this.alertMessage = "phone is required";
        this.alertType = "alert-danger";
      }

      if (this.comment === "") {
        this.alertMessage = "comment is required";
        this.alertType = "alert-danger";
      }

      if (this.alertMessage === "") {
        this.axios
          .post(this.$hostApi + this.path, {
            name: this.name,
            email: this.email,
            phone: this.phone,
            comment: this.comment,
          })
          .then((response) => {
            this.resetState();
            this.alertMessage = response.data;
            this.alertType = "alert-success";
          })
          .catch(function (error) {
            console.log(error.response);
            alert(error.response.statusText);
          });
      }
    },
    closeAlertEvent() {
      this.alertMessage = "";
      this.alertType = "";
    },
  },
};
</script>