<template>
  <header id="header-wrap" data-spy="affix" data-offset-top="0">
    <div class="container">
      <div class="row">
        <div class="col-sm-12">
          <nav class="navbar navbar-default">
            <!-- Brand and toggle get grouped for better mobile display -->
            <div class="navbar-header">
              <button
                type="button"
                class="navbar-toggle collapsed"
                data-toggle="collapse"
                data-target="#bs-example-navbar-collapse-1"
                aria-expanded="false"
              >
                <span class="sr-only">Toggle navigation</span>
                <span class="icon-bar"></span>
                <span class="icon-bar"></span>
                <span class="icon-bar"></span>
              </button>
              <a class="navbar-brand" href="/">
                <img src="assets/images/logo.png" alt="logo" />
              </a>
            </div>
          </nav>
        </div>
      </div>
    </div>
  </header>
</template>
<script>
export default {
  name: "Header",
  components: {},
  methods: {
    clickEvent(action, homepageSection) {
      this.$gtag.event(action, {
        homepage_section: homepageSection,
      });
    },
  },
};
</script>
